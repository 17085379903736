import iconHealthcareProvider from '../assets/images/icon-healthcare-provider.png';
import iconCalculator from '../assets/images/icon-calculator.svg';
import iconMeal2 from '../assets/images/icon-meal-2.png';
import iconWatch from '../assets/images/icon-watch.png';
import iconBolusBlank from '../assets/images/icon-bolus.png';
import iconMealDifficult from '../assets/images/icon-meal-difficult.png';
import iconActivity from '../assets/images/icon-activity.png';
import iconIll from '../assets/images/icon-ill.png';
import iconMeal from '../assets/images/icon-meal.png';
import iconWarning from '../assets/images/warning-icon.png';
import { IDataUnits, IEdNote } from '../types';
import {
    DataResponseInsulinUsageTrendItem,
    InsulinUsage,
    LearningMaterialProgressResponseItemData,
    LearningMaterialResourceResponseItemData,
} from '../model/models';

export default class ConstantsHelper {
    public static readonly NameTag = 'constants.appName';
    public static readonly NameTagAlt = 'constants.appNameAlt';
    public static readonly NameTagAlt2 = 'constants.appNameAlt2';
    public static readonly CaptionHyperHypo = 'constants.hyperHypo';
    public static readonly CaptionToday = 'constants.goToNewesttReportWeek';
    public static readonly ReadingsLabelHigh = 'constants.high';
    public static readonly ReadingsLabelLow = 'constants.low';
    public static readonly ReadingsLabelAutomated = 'constants.automated';
    public static readonly ReadingsLabelManual = 'constants.manual';
    public static readonly ReadingsLabelLimited = 'constants.limited';
    public static readonly DataSeriesHigh = 'high';
    public static readonly DataSeriesLow = 'low';
    public static readonly DataSeriesNormal = 'normal';
    public static readonly DataSeriesBolus = 'bolus';
    public static readonly DataSeriesInsulinDelivery = 'insulinDelivery';
    public static readonly DataSeriesAutomated = 'Automated';
    public static readonly DataSeriesManual = 'Manual';
    public static readonly DataSeriesLimited = 'Limited';
    public static readonly DataSubTypeTopOfHour = 'toh';
    public static readonly EgvLowThreshold = 40;
    public static readonly EgvHighThreshold = 400;
    public static readonly ReadingsRangeEgvMax = 401;
    public static readonly ReadingsRangeEgvMin = 39;
    public static readonly ReadingsGoodEgvMax = 180;
    public static readonly ReadingsGoodEgvMin = 70;
    public static readonly ReadingsUnit = 'mg/dL';
    public static readonly ReadingsWindowOffsetBegTimeOfDay = 120;
    public static readonly ReadingsWindowOffsetBegBolus = 180;
    public static readonly ReadingsWindowOffsetEnd = 60;
    public static readonly ReadingsWindowOffsetEndBolusShort = 15;
    public static readonly DashboardMaxEventsPerBolusInsight = 10;
    // These are the keys that are maintained intersession with no Patient Identifying Information retained
    public static readonly IntersessionKeys = {
        calendarDate: 'calendarDate',
        contributingEventBeg: 'contributingEventBeg',
        contributingEventEnd: 'contributingEventEnd',
        contributingEventId: 'contributingEventId',
        dashboardHasReferrer: 'dashboardHasReferrer', // Make sure this comes before dashboardBeg and dashboardEnd
        dashboardBeg: 'dashboardBeg',
        dashboardEnd: 'dashboardEnd',
        idTag: 'idTag',
        insightId: 'insightId',
        showLegend: 'showLegend',
        signOutCanProceed: 'signOutCanProceed',
    };
    public static readonly EducationalNotesLow: IEdNote[] = [
        {
            icon: iconBolusBlank,
            alt: 'constants.iLowTakeInsulinMore',
            text: 'constants.qLowTakeInsulinMore',
        },
        {
            icon: iconMeal,
            alt: 'constants.iLowEatAsMuch',
            text: 'constants.qLowEatAsMuch',
        },
        {
            icon: iconMealDifficult,
            alt: 'constants.iLowHadMealsDifficult',
            text: 'constants.qLowHadMealsDifficult',
        },
        {
            icon: iconActivity,
            alt: 'constants.iLowRoutineLevelChange',
            text: 'constants.qLowRoutineLevelChange',
        },
        {
            icon: iconWarning,
            alt: 'constants.iConsiderFactorsCaused',
            text: 'constants.qConsiderFactorsCaused',
            isWarning: true,
        },
    ];
    public static readonly EducationalNotesHigh: IEdNote[] = [
        {
            icon: iconBolusBlank,
            alt: 'constants.iHighTakeInsulinLess',
            text: 'constants.qHighTakeInsulinLess',
        },
        {
            icon: iconMealDifficult,
            alt: 'constants.iHighHadMealsDifficult',
            text: 'constants.qHighHadMealsDifficult',
        },
        {
            icon: iconActivity,
            alt: 'constants.iHighRoutineLevelChange',
            text: 'constants.qHighRoutineLevelChange',
        },
        {
            icon: iconIll,
            alt: 'constants.iHighSickOrStressed',
            text: 'constants.qHighSickOrStressed',
        },
        {
            icon: iconWarning,
            alt: 'constants.iConsiderFactorsCaused',
            text: 'constants.qConsiderFactorsCaused',
            isWarning: true,
        },
    ];
    public static readonly EducationalNotesInsulinInsights: IEdNote[] = [
        {
            icon: iconHealthcareProvider,
            alt: 'loyalty.insulin.factors.talk',
            text: 'loyalty.insulin.factors.talk',
            bolusSettings: true,
        },
        {
            icon: iconCalculator,
            alt: 'loyalty.insulin.factors.useCalculator',
            text: 'loyalty.insulin.factors.useCalculator',
        },
        {
            icon: iconMeal2,
            alt: 'loyalty.insulin.factors.meals',
            text: 'loyalty.insulin.factors.meals',
        },
        {
            icon: iconWatch,
            alt: 'loyalty.insulin.factors.bolusUnits',
            text: 'loyalty.insulin.factors.bolusUnits',
        },
        {
            icon: iconBolusBlank,
            alt: 'loyalty.insulin.factors.bolusPercentage',
            text: 'loyalty.insulin.factors.bolusPercentage',
            bolusRatioGoal: true,
        },
        {
            icon: iconWarning,
            alt: 'constants.iConsiderFactorsCaused',
            text: 'constants.qConsiderFactorsCaused',
            isWarning: true,
        },
    ];
    public static readonly Chart = {
        colorInRange: 'rgba(163,163,163,0.8)',
        colorMarkerHorizHigh: '#FFA700',
        colorMarkerHorizInRange: '#6D6D6D',
        colorMarkerHorizLow: '#F75E4C',
        colorMarkerVert: 'rgba(100,100,10,0.6)',
        colorMarkerVertXoverDaily: 'rgba(0,0,0,1.0)',
        colorMarkerVertXoverHourly: 'rgba(0,0,0,0.7)',
        colorManual: 'rgb(123,172,221)',
        colorAutomated: 'rgb(148,117,211)',
        colorLimited: 'rgb(84,106,124)',
        colorActivity: 'rgb(43,218,123)',
        colorInsulinPaused: 'rgb(247,94,76)',
        colorInsulinMax: 'rgb(255,167,0)',
        colorBasalMode: 'rgb(26,209,219)',
        colorBorder: 'silver',
        colorBlank: '#f0f0f0',
        colorTransparent: 'transparent',
        colorBolus: '#abc9e8',
        colorBasal: '#d3ada3',
        colorTimeInRange: '#2bda7b',
        colorMarker: '#e6e6e6',
        colorBarLabelA: '#000000',
        colorBarLabelB: '#5a5a5a',
        colorTickMark: '#6c757d',
    };
    public static readonly httpCallAndErrorKeys = {
        general: 'general',
        sendFeedback: 'sendFeedback',
        fetchBackEndId: 'fetchBackEndId',
        updateProfile: 'updateProfile',
        fetchProfile: 'fetchProfile',
        fetchLearningMaterialResources: 'fetchLearningMaterialResources',
        fetchLearningMaterialOp5Progress: 'fetchLearningMaterialOp5Progress',
        fetchDashboardInsulinUsage: 'fetchDashboardInsulinUsage',
        fetchDashboardInsulinUsageTrend: 'fetchDashboardInsulinUsageTrend',
        fetchDashboardSummary: 'fetchDashboardSummary',
        fetchDashboardSummaryGlucoseTrend: 'fetchDashboardSummaryGlucoseTrend',
        fetchDashboard: 'fetchDashboard',
        fetchInsightEventReadings: 'fetchInsightEventReadings',
        fetchInsightEventBoluses: 'fetchInsightEventBoluses',
        fetchInsightEventModes: 'fetchInsightEventModes',
        fetchHcpReport: 'fetchHcpReport',
        fetchClinics: 'fetchClinics',
        acceptClinicInvitation: 'acceptClinicInvitation',
        denyClinicInvitation: 'denyClinicInvitation',
    };

    public static readonly analyticsEventNames = {
        CLICK_INSIGHT_FEEDBACK: 'click insight feedback',
        FEEDBACK_VALUE_TRUE: 'yes',
        FEEDBACK_VALUE_FALSE: 'no',
        REDIRECT_TO_LOGIN: 'redirect to login',
        SUCCESSFUL_LOGIN: 'successful login',
        FAILED_LOGIN: 'failed login',
        LOG_OUT: 'log out',
        VIEW_EVENT_DETAILS: 'view event details',
        VIEW_INSIGHT_DETAILS: 'view insight details',
        CLICK_INSIGHT_DETAILS: 'click insight details',
        VIEW_DASHBOARD: 'view dashboard',
        VIEW_OP5_DETAILS: 'view OP5 details',
        VIEW_OP5_LEARN_MORE: 'view OP5 learn more',
        VIEW_OP5_RESOURCES: 'view OP5 resources',
        VIEW_INSULIN_USAGE_DETAILS: 'view insulin usage details',
        EXPAND_INSILIN_TDI_RATIO: 'expand insulin tdi ratio view',
        DOWNLOAD_PHYSICIAN_REPORT: 'download physician report',
        EULA_AND_CONF_AGR_LANDING: 'eula and confidentiality agreement landing',
        EULA_AND_CONF_AGR_EULA_CHANGED: 'eula check changed',
        EULA_AND_CONF_AGR_EULA_LINK: 'eula link invoked',
        EULA_AND_CONF_AGR_CONF_AGR_CHANGED: 'confidentiality agreement check changed',
        EULA_AND_CONF_AGR_CONF_AGR_LINK: 'confidentiality agreement link invoked',
        EULA_AND_CONF_AGR_ACCEPT: 'eula and confidentiality agreement accepted',
    };

    public static readonly urlParamsConstants = {
        oktaIssuer: {
            runtimeKey: 'REACT_APP_OKTA_ISSUER',
            settingsKey: 'oktaIssuer',
            urlKey: 'oktaIssuer',
        },
        oktaClientID: {
            runtimeKey: 'REACT_APP_OKTA_CLIENT_ID',
            settingsKey: 'oktaClientID',
            urlKey: 'oktaClientID',
        },
        endpointPwdUrlPrefix: {
            runtimeKey: 'REACT_APP_ENDPOINT_PWD_URL',
            settingsKey: 'endpointPwdUrlPrefix',
            urlKey: 'endpoint',
        },
        endpointPdfUrlPrefix: {
            runtimeKey: 'REACT_APP_ENDPOINT_PDF_URL',
            settingsKey: 'endpointPdfUrlPrefix',
            urlKey: 'endpointPdf',
        },
        insuletIDURL: {
            runtimeKey: 'REACT_APP_INSULET_ID_URL',
            settingsKey: 'insuletIDURL',
            urlKey: 'insuletIDURL',
        },
        insuletOktaApp: {
            runtimeKey: 'REACT_APP_INSULET_ID_APP_NAME',
            settingsKey: 'insuletOktaApp',
            urlKey: 'insuletOktaApp',
        },
        pwdAppUrl: {
            runtimeKey: 'REACT_APP_PWD_APP_URL',
            settingsKey: 'pwdAppURL',
            urlKey: 'pwdAppURL',
        },
        hcpAppUrl: {
            runtimeKey: 'REACT_APP_HCP_APP_URL',
            settingsKey: 'pwdAppURL',
            urlKey: 'pwdAppURL',
        },
    };

    public static readonly ErrorLevels = {
        clear: 0,
        info: 1,
        success: 2,
        warning: 3,
        error: 4,
    };

    public static UnitsTable: IDataUnits = {
        egv: 'mg/dL',
        basal: 'U',
        bolus: 'U',
        carbs: 'g',
    };

    public static LearningMaterialProgressData: LearningMaterialProgressResponseItemData = {
        progress: {
            body: 'test lm progress body',
            featuredImage: 'test lm progress featuredImage',
            weekNumber: 3,
            title: 'test title',
        },
    };

    public static LearningMaterialResourceData: LearningMaterialResourceResponseItemData = {
        resources: {
            body: 'test lm resource body',
            featuredImage: 'test lm resource featuredImage',
            linkToMoreResources: 'test lm resource linkToMoreResources',
            title: 'test lm resource title',
            weekNumber: 3,
            buttonText: 'test button text',
        },
    };

    public static LearningMaterialInsulinUsage: InsulinUsage = {
        dailyUsage: {
            avgInsulinTotal: 17.5,
            bolusStatistics: {
                avgNumber: 9.6,
                timeOfDay: {
                    nighttime: {
                        avgNumber: 0.4,
                        timeRange: {
                            start: '12 AM',
                            end: '6 AM',
                        },
                    },
                    morning: {
                        avgNumber: 4.7,
                        timeRange: {
                            start: '6 AM',
                            end: '12 PM',
                        },
                    },
                    afternoon: {
                        avgNumber: 3.7,
                        timeRange: {
                            start: '12 PM',
                            end: '6 PM',
                        },
                    },
                    evening: {
                        avgNumber: 0.7,
                        timeRange: {
                            start: '6 PM',
                            end: '12 AM',
                        },
                    },
                },
            },
            insulinRatio: {
                basal: 0,
                bolus: 100,
            },
            insulinUnits: {
                basal: 0,
                bolus: 17.5,
            },
            goals: {
                bolusRatio: {
                    gte: 50,
                },
            },
        },
        displayUsageExplanation: {
            factorsToConsider: {
                bolusRatioGoal: true,
                bolusSettings: false,
            },
        },
    };

    public static InsulinUsageTrendData: DataResponseInsulinUsageTrendItem = {
        item: {
            weeklyTrend: {
                weeks: [
                    {
                        avgInsulinTotal: 349.3,
                        bolusStatistics: {
                            avgNumber: 9.6,
                            ratioToInsulinTotal: 100,
                        },
                        glucoseInRange: 79,
                    },
                    null,
                    {
                        avgInsulinTotal: 17.5,
                        bolusStatistics: {
                            avgNumber: 9.6,
                            ratioToInsulinTotal: 100,
                        },
                        glucoseInRange: 79,
                    },
                ],
                goals: {
                    bolusRatio: {
                        gte: 50,
                    },
                    glucoseInRange: {
                        gte: 70,
                    },
                },
            },
        },
    };

    public static readonly oktaLoginCallbackErrors = {
        userNotAssignedToApplication: 'User is not assigned to the client application.',
    };

    public static readonly LoginCallbackErrors = {
        key: 'loginCallbackError',
        title: 'Login Callback Error',
    };
}
