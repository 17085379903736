import React, { useEffect, useState } from 'react';

import { INotification } from 'src/types';

import AlertToast from './AlertToast';

interface INotificationProps {
    data?: INotification;
}

function Notification({ data }: INotificationProps) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (data) {
            setIsOpen(true);
        }
    }, [data]);

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <AlertToast
            data-testid="notification"
            message={data?.message}
            onClose={handleClose}
            open={isOpen}
            severity={data?.severity}
            title={data?.title}
        />
    );
}

export default Notification;
