import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parse } from 'node-html-parser';
import { StatusCodes } from 'http-status-codes';

import { homeInitialState, homeReducer } from '.';

import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import {
    DataResponseInsight3,
    DataResponseInsight3GlucoseTrend,
    DataResponseInsulinUsageItem,
    DataResponseInsulinUsageTrendItem,
    LearningMaterialProgress,
    LearningMaterialProgressResponseItem,
    LearningMaterialResourceResponseItem,
    LearningMaterialResource,
    PatientProfileBody,
    ReleaseNotesJSONDataItem,
    Clinic,
} from '../../model/models';
import {
    IDashboardRequest,
    IHome,
    ICachedResponse,
    IInsightFeedback,
    IDashboardFetchPayload,
    IDashboardSummaryFetchPayload,
    IDashboardSummaryGlucoseTrendFetchPayload,
    IIDataItemReadings,
    IReadingSub,
    IPatient,
    IInsulinUsage,
    IAcceptClinicInvitationParams,
    IDenyClinicInvitationParams,
} from '../../types';
import { RootState } from '../store';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import InsightHelper from '../../helpers/InsightHelper';
import EventHelper from '../../helpers/EventHelper';
import StringHelper from '../../helpers/StringHelper';
import SystemHelper from '../../helpers/SystemHelper';
import UiHelper from '../../helpers/UiHelper';
import UtilityHelper from '../../helpers/UtilityHelper';

// https://www.newline.co/@bespoyasov/how-to-use-thunks-with-redux-toolkit-and-typescript--1e65fc64
type Error = {
    message: string;
};

export const sendFeedback = createAsyncThunk<IInsightFeedback, IInsightFeedback, { rejectValue: Error }>(
    'patient​/sendFeedback',
    async (feedback: IInsightFeedback, { getState, rejectWithValue }: any) => {
        const home = getState().home as IHome;
        const url = `${home.endpointPwdUrlPrefix}/patient/insights/feedback`;
        const response = await SystemHelper.Fetch(
            home,
            url,
            {
                method: 'POST',
                body: JSON.stringify({ feedback }),
            },
            true
        );

        if (!(response.status === StatusCodes.OK || response.status === StatusCodes.NO_CONTENT)) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedFeedback')} (${
                    response.msg
                })`,
            });
        }

        return feedback;
    }
);

export const updateProfile = createAsyncThunk<PatientProfileBody, PatientProfileBody, { rejectValue: Error }>(
    'patient​/updateProfile',
    async (updatePayload: PatientProfileBody, { getState, rejectWithValue }: any) => {
        const home = getState().home as IHome;
        const url = `${home.endpointPwdUrlPrefix}/patient/profile`;
        const response = await SystemHelper.Fetch(
            home,
            url,
            {
                method: 'POST',
                body: JSON.stringify(updatePayload),
            },
            true
        );

        if (!(response.status === StatusCodes.OK || response.status === StatusCodes.NO_CONTENT)) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedProfileUpdate')} (${
                    response.msg
                })`,
            });
        }

        return updatePayload;
    }
);

const fetchProfileSub = async (home: IHome, rejectWithValue: any): Promise<IPatient> => {
    // Use local token data since they may not have been stored in Redux store yet
    const url = `${home.endpointPwdUrlPrefix}/patient/profile?`;
    const response = await SystemHelper.Fetch(home, url, undefined, undefined, true);
    const payload: IPatient = response?.data?.data?.item ?? {};
    const status = response.status;

    payload.reportDaysAvailableString = '0';
    payload.isEnrolled = false;
    payload.isUnenrolled = false;
    payload.isOnboarded = false;
    payload.receivedData = true;
    payload.userType = 'patient';

    /*
        There are 4 possible cases which will trigger 3 different UX on front end:
            a) User in PodderCentral and enrolled in Discover:
                Profile in database?
                Yes: 200 OK
                No:
                    Trigger ETL (pull data into the database in real-time):
                        Success: Proceed with “d”
                        Failure: 424 Failed Dependency, show “Oh No” UX
            b) In PodderCentral and never enrolled in Discover:
                enrollmentStatus == true: 200 OK
                enrollmentStatus == false: 401 Unauthorized, show “You are unenrolled” UX
            c) In PodderCentral and chose to unenroll:
                enrollmentStatus == true: 200 OK
                enrollmentStatus == false: 401 Unauthorized, show “You are unenrolled” UX
            d) In PodderCentral and enrollment pending:
                enrollmentPending == false*: 200 OK
                enrollmentPending == true*: 404 Not Found, show “Something is missing” UX.


        200 Normal ops
        401 Show "You are unenrolled" UX
        404 Show "Something is missing" UX
        else Show "Oh No" UX
    */
    if (status === StatusCodes.OK && !UtilityHelper.IsNull(payload.firstJoined)) {
        payload.isEnrolled = true;
    } else if (status === StatusCodes.UNAUTHORIZED) {
        payload.isUnenrolled = true;
    } else if (status !== StatusCodes.NOT_FOUND) {
        return rejectWithValue({ message: `${response.msg}.` });
    }

    if (UtilityHelper.IsNull(payload.clinicName)) {
        payload.clinicName = 'Clinic Name Here';
    }

    if (UtilityHelper.IsNull(payload.physicianName)) {
        payload.physicianName = 'Physician Name Here';
    }

    if (UtilityHelper.IsNull(payload.dateOfBirth)) {
        payload.dateOfBirth = DateTimeHelper.GetIsoNow();
    }

    return payload;
};

export const fetchProfile = createAsyncThunk<IPatient, any, { rejectValue: Error }>(
    'patient/fetchProfile',
    async (params: any, { getState, rejectWithValue }: any) => {
        const home = getState().home as IHome;

        if (
            !(
                home.authentication?.oktaData?.access &&
                home.authentication?.oktaData?.aud &&
                home.authentication?.oktaData?.id &&
                home.authentication?.oktaData?.expires &&
                DateTimeHelper.GetDurationInSeconds(
                    DateTimeHelper.GetIsoNow(),
                    home.authentication?.oktaData?.expires
                ) > 0
            )
        ) {
            return rejectWithValue({
                message: AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'signIn.signInRequired'),
            });
        } else {
            return fetchProfileSub(home, rejectWithValue);
        }
    }
);

export const fetchBackEndId = createAsyncThunk<string, any, { rejectValue: Error }>(
    'patient​/fetchBackEndId',
    async (params: any, { getState, rejectWithValue }: any) => {
        const home = getState().home as IHome;
        const queryParams = [''];
        const url = `${home.endpointPwdUrlPrefix}/?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(home, url, undefined, undefined, true, true);

        if (response.status !== StatusCodes.OK) {
            return rejectWithValue({
                message: `${response.msg}.`,
            });
        }

        return response.data;
    }
);

interface PatientSmsNumber {
    smsNumber: string;
}

export const saveAndConfirmSmsNumber = createAsyncThunk(
    'patient/saveAndConfirmSmsNumber',
    async (updatePayload: PatientSmsNumber, { getState, rejectWithValue }: any) => {
        const home = getState().home as IHome;
        const url = `${home.endpointPwdUrlPrefix}/patient/profile/save-and-confirm-sms-number`;

        const response = await SystemHelper.Fetch(
            home,
            url,
            {
                method: 'PUT',
                body: JSON.stringify(updatePayload),
            },
            true
        );

        if (response.status !== StatusCodes.OK) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedSmsNumberUpdate')}`,
            });
        }

        return updatePayload;
    }
);

export const fetchLearningMaterialResources = createAsyncThunk<
    ICachedResponse<LearningMaterialResourceResponseItem>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchLearningMaterialResources', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const home = getState().home as IHome;
    const learningMaterialResourceItem: LearningMaterialResourceResponseItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `i18n=${params.i18n ?? 'en_US'}`];
    const url = `${home.endpointPwdUrlPrefix}/patient/learning-material/resources?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(home, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        learningMaterialResourceItem.item = response.data?.data?.item;
    } else {
        learningMaterialResourceItem.item = {
            resources: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedLearningMaterialResource')} (${
                response.msg
            })`,
        });
    }

    return {
        data: learningMaterialResourceItem,
    };
});

export const fetchLearningMaterialOp5Progress = createAsyncThunk<
    ICachedResponse<LearningMaterialProgressResponseItem>,
    IDashboardRequest,
    { rejectValue: Error }
>(
    'patient​/fetchLearningMaterialOp5Progress',
    async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
        const home = getState().home as IHome;
        const learningMaterialProgress: LearningMaterialProgressResponseItem = {
            item: null,
        };
        const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `i18n=${params.i18n ?? 'en_US'}`];
        const url = `${home.endpointPwdUrlPrefix}/patient/learning-material/op5-progress?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(home, url, undefined, false, true);
        const haveValidStatus =
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.BAD_REQUEST ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR;

        if (response.status === StatusCodes.OK) {
            learningMaterialProgress.item = response.data?.data?.item;
        } else {
            learningMaterialProgress.item = {
                progress: {},
            };
        }

        if (!haveValidStatus) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    home?.anI18Nextlib,
                    'errors.failedLearningMaterialProgress'
                )} (${response.msg})`,
            });
        }

        return {
            data: learningMaterialProgress,
        };
    }
);

export const fetchDashboardInsulinUsage = createAsyncThunk<
    ICachedResponse<DataResponseInsulinUsageItem>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchDashboardInsulinUsage', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const home = getState().home as IHome;
    const dashboardInsulinUsage: DataResponseInsulinUsageItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${home.endpointPwdUrlPrefix}/patient/dashboard/insulin-usage?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(home, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        dashboardInsulinUsage.item = response.data?.data?.item;
    } else {
        dashboardInsulinUsage.item = {
            dailyUsage: {},
            displayUsageExplanation: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedDashboardInsulinUsage')} (${
                response.msg
            })`,
        });
    }

    return {
        data: dashboardInsulinUsage,
    };
});

export const fetchDashboardInsulinUsageTrend = createAsyncThunk<
    ICachedResponse<DataResponseInsulinUsageTrendItem>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchDashboardInsulinUsageTrend', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const home = getState().home as IHome;
    const dashboardInsulinUsageTrend: DataResponseInsulinUsageTrendItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${home.endpointPwdUrlPrefix}/patient/dashboard/insulin-usage/trend?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(home, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        dashboardInsulinUsageTrend.item = response.data?.data?.item;
    } else {
        dashboardInsulinUsageTrend.item = {
            weeklyTrend: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedDashboardInsulinUsageTrend')} (${
                response.msg
            })`,
        });
    }

    return {
        data: dashboardInsulinUsageTrend,
    };
});

export const fetchDashboardSummary = createAsyncThunk<
    ICachedResponse<IDashboardSummaryFetchPayload>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchDashboardSummary', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const home = getState().home as IHome;
    const dashboardSummaryFetchPayload: IDashboardSummaryFetchPayload = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${home.endpointPwdUrlPrefix}/patient/dashboard/summary?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(home, url, undefined, false, true);

    if (response.status === StatusCodes.OK) {
        const wrapper: DataResponseInsight3 = response.data;

        dashboardSummaryFetchPayload.item = wrapper?.data?.item;
    } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
        dashboardSummaryFetchPayload.item = [] as any;
    }

    if (dashboardSummaryFetchPayload.item === null) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedDashboardSummary')} (${
                response.msg
            })`,
        });
    }

    return {
        data: dashboardSummaryFetchPayload,
    };
});

export const fetchDashboardSummaryGlucoseTrend = createAsyncThunk<
    ICachedResponse<IDashboardSummaryGlucoseTrendFetchPayload>,
    IDashboardRequest,
    { rejectValue: Error }
>(
    'patient​/fetchDashboardSummaryGlucoseTrend',
    async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
        const home = getState().home as IHome;
        const dashboardSummaryGlucoseTrendFetchPayload: IDashboardSummaryGlucoseTrendFetchPayload = {
            item: null,
        };

        const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
        const url = `${home.endpointPwdUrlPrefix}/patient/dashboard/summary/glucose-trend?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(home, url, undefined, false, true);

        if (response.status === StatusCodes.OK) {
            const wrapper: DataResponseInsight3GlucoseTrend = response.data;

            dashboardSummaryGlucoseTrendFetchPayload.item = wrapper?.data?.item;
        } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
            dashboardSummaryGlucoseTrendFetchPayload.item = [] as any;
        }

        if (dashboardSummaryGlucoseTrendFetchPayload.item === null) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    home?.anI18Nextlib,
                    'errors.failedDashboardSummaryGlucoseTrend'
                )} (${response.msg})`,
            });
        }

        return {
            id: params.endUtc,
            data: dashboardSummaryGlucoseTrendFetchPayload,
        };
    }
);

export const fetchDashboard = createAsyncThunk<
    ICachedResponse<IDashboardFetchPayload>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchDashboard', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const home = getState().home as IHome;
    const dashboardFetchPayload: IDashboardFetchPayload = {
        items: null,
        totalCount: 0,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `limit=${params.limit}`, `offset=${params.offset}`];
    const url = `${home.endpointPwdUrlPrefix}/patient/dashboard?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(home, url, undefined, false, true);

    if (response.status === StatusCodes.OK) {
        const wrapper: IDashboardFetchPayload = response.data?.data;

        dashboardFetchPayload.items = wrapper.items;
        dashboardFetchPayload.totalCount = wrapper.totalCount;
    } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
        dashboardFetchPayload.items = [];
    }

    if (dashboardFetchPayload.items === null) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedDashboard')} (${response.msg})`,
        });
    }

    return {
        id: params.end,
        data: dashboardFetchPayload,
    } as IDashboardFetchPayload;
});

export const fetchInsightEventReadings = createAsyncThunk<
    ICachedResponse<IIDataItemReadings>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchInsightEventReadings', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const home = getState().home as IHome;
    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${home.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/readings?`;
    const response = await SystemHelper.Fetch(home, url, undefined, undefined, true);

    if (response.status !== StatusCodes.OK) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedEventReadings')} (${
                response.msg
            })`,
        });
    } else {
        const readings = response.data?.data?.item?.readings ?? [];

        dataReadings = {
            readings: readings,
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...home.currentReadingCombo,
            ...dataReadings,
            fetchedReadings: true,
        },
    };
});

export const fetchInsightEventBoluses = createAsyncThunk<
    ICachedResponse<IIDataItemReadings>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchInsightEventBoluses', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const home = getState().home as IHome;
    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${home.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/boluses?`;
    const response = await SystemHelper.Fetch(home, url, undefined, undefined, true);

    if (
        !(
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR
        )
    ) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedEventBoluses')} (${
                response.msg
            })`,
        });
    } else {
        dataReadings = {
            boluses: response.data?.data?.item?.boluses ?? [],
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...home.currentReadingCombo,
            ...dataReadings,
            fetchedBoluses: true,
        },
    };
});

export const fetchInsightEventModes = createAsyncThunk<
    ICachedResponse<IReadingSub>,
    IDashboardRequest,
    { rejectValue: Error }
>('patient​/fetchInsightEventModes', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const home = getState().home as IHome;
    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${home.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/modes?`;
    const response = await SystemHelper.Fetch(home, url, undefined, undefined, true);

    if (
        !(
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR
        )
    ) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedEventModes')} (${response.msg})`,
        });
    } else {
        dataReadings = {
            modes: response.data?.data?.item?.modes ?? [],
            podStatuses: response.data?.data?.item?.podStatuses ?? [],
            submodes: response.data?.data?.item?.submodes ?? [],
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...home.currentReadingCombo,
            ...dataReadings,
            fetchedModes: true,
        },
    };
});

export const fetchHcpReport = createAsyncThunk<number, IDashboardRequest, { rejectValue: Error }>(
    'patient​/fetchHcpReport',
    async (params: any, { getState, rejectWithValue }: any) => {
        const home = getState().home as IHome;

        if ((params?.numDays ?? -1) < 0) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.invalidResponse')}`,
            });
        }

        return params?.numDays;
    }
);

export const fetchClinics = createAsyncThunk<Clinic[], any, { rejectValue: Error }>(
    'patient​/fetchClinics',
    async (params: any, { getState, rejectWithValue }: any) => {
        const home = getState().home as IHome;
        const url = `${home.endpointPwdUrlPrefix}/patient/clinics`;
        const response = await SystemHelper.Fetch(home, url, undefined, undefined, true);

        if (response.status !== StatusCodes.OK && response.status !== StatusCodes.NOT_FOUND) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedClinics')} (${
                    response.msg
                })`,
            });
        }

        return response.data?.data?.items ?? [];
    }
);

export const acceptClinicInvitation = createAsyncThunk<void, IAcceptClinicInvitationParams, { rejectValue: Error }>(
    'patient/acceptClinicInvitation',
    async (params, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const home = state.home;
        const url = `${home.endpointPwdUrlPrefix}/patient/invitations/${params.invitationId}/accept`;
        const response = await SystemHelper.Fetch(
            home,
            url,
            {
                method: 'POST',
            },
            true
        );

        if (response.status !== StatusCodes.OK) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedAcceptClinicInvitation')} (${
                    response.msg
                })`,
            });
        }

        return null;
    }
);

export const denyClinicInvitation = createAsyncThunk<void, IDenyClinicInvitationParams, { rejectValue: Error }>(
    'patient/denyClinicInvitation',
    async (params, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const home = state.home;
        const url = `${home.endpointPwdUrlPrefix}/patient/invitations/${params.invitationId}/deny`;
        const response = await SystemHelper.Fetch(
            home,
            url,
            {
                method: 'POST',
            },
            true
        );

        if (response.status !== StatusCodes.OK) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(home?.anI18Nextlib, 'errors.failedDenyClinicInvitation')} (${
                    response.msg
                })`,
            });
        }

        return null;
    }
);

/*createSlice - a function that accepts an initial state, an object full of reducer
functions, and a slice name that automatically generates action creators and action
types that correspond to the reducers and state.  Internally it uses createAction and createReducer*/

export const homeSlice = createSlice({
    name: 'home', // name used in action types
    initialState: homeInitialState, // initial state for the reducer
    reducers: homeReducer,
    extraReducers: (builder) => {
        builder
            .addCase(sendFeedback.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.sendFeedback, [
                    action.meta?.arg?.insightId,
                    action.meta?.arg?.feedbackValue,
                    action.meta?.arg?.insightIdParent,
                ]);

                state.control.activeHttpCalls[callKey] = true;

                const insight = state.cachedInsights[action.meta.arg.insightId];

                if (insight) {
                    insight.feedbackValue = action.meta.arg.feedbackValue;
                }

                const message = AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.sendingFeedback');

                state.control.message = message;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message,
                    sticky: false,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.feedback'),
                });
            })
            .addCase(sendFeedback.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.sendFeedback, [
                    action.meta?.arg?.insightId,
                    action.meta?.arg?.feedbackValue,
                    action.meta?.arg?.insightIdParent,
                ]);

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    [action.payload.insightId, action.payload.insightIdParent].forEach((insightId) => {
                        if (insightId) {
                            const insight = state.cachedInsights[insightId];

                            if (insight) {
                                insight.feedbackValue = action.payload.feedbackValue;
                            }
                        }
                    });
                }

                const message = AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.thankYouFeedback');

                state.control.message = message;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message,
                    showAlways: false,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.feedback'),
                });
                state.loadingSemaphore--;
            })
            .addCase(sendFeedback.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.sendFeedback, [
                    action.meta?.arg?.insightId,
                    action.meta?.arg?.feedbackValue,
                    action.meta?.arg?.insightIdParent,
                ]);
                const message =
                    action?.payload?.message ??
                    action?.error?.message ??
                    AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error');

                state.control.message = message;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.feedback'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchBackEndId.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);

                state.control.activeHttpCalls[callKey] = true;
                state.versionBackEnd = undefined;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.backEndId'),
                });
            })
            .addCase(fetchBackEndId.fulfilled, (state, { payload }) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                const dataRecs = parse(payload, {
                    lowerCaseTagName: true,
                    comment: false,
                    blockTextElements: {
                        script: false,
                        style: false,
                    },
                })
                    ?.getElementsByTagName('tr')
                    ?.map((trEntry) => {
                        const labels = trEntry.getElementsByTagName('th');
                        const values = trEntry.getElementsByTagName('td');

                        return labels?.length > 0 && values?.length > 0
                            ? `${labels[0].innerText.toLowerCase()}=${values[0].innerText}`
                            : '';
                    })
                    ?.filter((line) => line?.trim()?.length > 0);
                const data: ReleaseNotesJSONDataItem = {
                    cICDBuildPipelineID: `${dataRecs.find(
                        (line) => line.indexOf('ci/cd build pipeline id=') > -1
                    )}=`.split('=')[1],
                    cICDReleasePipelineID: `${dataRecs.find(
                        (line) => line.indexOf('ci/cd release pipeline id=') > -1
                    )}=`.split('=')[1],
                    notes: [],
                    repositoryBranch: undefined,
                    storageAccess: 'ok',
                    version: `${dataRecs.find((line) => line.indexOf('version=') > -1)}=`.split('=')[1],
                };
                state.versionBackEnd = [
                    data.version.length > 0 ? data.version : '--',
                    data.cICDBuildPipelineID,
                    data.cICDReleasePipelineID,
                ]
                    .filter((entry) => entry.length > 0)
                    .join(' / ');
                state.errors[callKey] = {
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.backEndId'),
                };
                state.loadingSemaphore--;
            })
            .addCase(fetchBackEndId.rejected, (state) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message: `${AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.invalidResponse')}`,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.backEndId'),
                });
                state.loadingSemaphore--;
            })
            .addCase(updateProfile.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.updateProfile, [
                    `${action.meta?.arg?.disclaimerSeen}`,
                    `${action.meta?.arg?.welcomeSeen}`,
                    `${action.meta?.arg?.eulaAccepted}`,
                    `${action.meta?.arg?.confidentialityAgreementAccepted}`,
                ]);

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
                });
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.updateProfile, [
                    `${action.meta?.arg?.disclaimerSeen}`,
                    `${action.meta?.arg?.welcomeSeen}`,
                    `${action.meta?.arg?.eulaAccepted}`,
                    `${action.meta?.arg?.confidentialityAgreementAccepted}`,
                ]);

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.patient = {
                    ...state.patient,
                    ...action.payload,
                };
                state.errors[callKey] = action.payload
                    ? {
                          level: ConstantsHelper.ErrorLevels.success,
                          message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                          title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
                      }
                    : {
                          level: ConstantsHelper.ErrorLevels.error,
                          message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileNotFound'),
                          title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
                      };

                state.loadingSemaphore--;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.updateProfile, [
                    `${action.meta?.arg?.disclaimerSeen}`,
                    `${action.meta?.arg?.welcomeSeen}`,
                    `${action.meta?.arg?.eulaAccepted}`,
                    `${action.meta?.arg?.confidentialityAgreementAccepted}`,
                ]);

                state.patient.disclaimerSeen = false;
                state.patient.welcomeSeen = false;
                state.patient.eulaAccepted = false;
                state.patient.confidentialityAgreementAccepted = false;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message: `${AnI18NextLibHelper.Translate(
                        state.anI18Nextlib,
                        'errors.pwdProfilePutFailed'
                    )}.\n${AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.reason')}:  ${
                        action?.payload?.message
                    }`,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchProfile.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchProfile);

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
                });
            })
            .addCase(fetchProfile.fulfilled, (state, { payload }) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchProfile);

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.patient = {
                    ...payload,
                    firstJoined: UtilityHelper.CryptoEncrypt(payload.firstJoined),
                    firstName: UtilityHelper.CryptoEncrypt(payload.firstName),
                    id: UtilityHelper.CryptoEncrypt(payload.id),
                    lastName: UtilityHelper.CryptoEncrypt(payload.lastName),
                    reportDaysAvailableString: UtilityHelper.CryptoEncrypt(`${payload.reportDaysAvailable}`),
                    reportDaysAvailable: -999,
                };
                state.errors[callKey] = payload.id
                    ? {
                          level: ConstantsHelper.ErrorLevels.success,
                          message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                          title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
                      }
                    : {
                          level: ConstantsHelper.ErrorLevels.info,
                          message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileNotFound'),
                          title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
                      };
                state.loadingSemaphore--;
            })
            .addCase(fetchProfile.rejected, (state, { payload }) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchProfile);

                state.patient.disclaimerSeen = false;
                state.patient.welcomeSeen = false;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        payload?.message ??
                        `${AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.invalidResponse')}`,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchLearningMaterialResources.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialResources,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialResource'),
                });
            })
            .addCase(fetchLearningMaterialResources.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialResources,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const currentLearningMaterialResource: LearningMaterialResource = {
                        ...action.payload.data.item?.resources,
                    };

                    state.currentLearningMaterialResource = {
                        id: state.dashboardEnd,
                        data: currentLearningMaterialResource,
                        expires: DateTimeHelper.AddSecs(DateTimeHelper.GetIsoNow(), 60),
                    };
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(
                            state.anI18Nextlib,
                            'errors.failedLearningMaterialResource'
                        ),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchLearningMaterialResources.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialResources,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialResource'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchLearningMaterialOp5Progress.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialOp5Progress,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialProgress'),
                });
            })
            .addCase(fetchLearningMaterialOp5Progress.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialOp5Progress,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const currentLearningMaterialProgress: LearningMaterialProgress = {
                        ...action.payload.data.item.progress,
                    };

                    state.currentLearningMaterialProgress = {
                        id: state.dashboardEnd,
                        data: currentLearningMaterialProgress,
                        expires: DateTimeHelper.AddSecs(DateTimeHelper.GetIsoNow(), 60),
                    };

                    state.patient.isOnboarded = (currentLearningMaterialProgress.weekNumber ?? -1) === -1;

                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(
                            state.anI18Nextlib,
                            'errors.failedLearningMaterialProgress'
                        ),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchLearningMaterialOp5Progress.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialOp5Progress,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialProgress'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardInsulinUsage.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsage,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsage'),
                });
            })
            .addCase(fetchDashboardInsulinUsage.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsage,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const currentInsulinUsage: IInsulinUsage = {
                        ...state.currentInsulinUsage,
                        ...action.payload.data.item,
                    };

                    state.currentInsulinUsage = currentInsulinUsage;
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsage'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardInsulinUsage.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsage,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsage'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardInsulinUsageTrend.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsageTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsageTrend'),
                });
            })
            .addCase(fetchDashboardInsulinUsageTrend.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsageTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const currentInsulinUsage: IInsulinUsage = {
                        ...state.currentInsulinUsage,
                        ...action.payload.data.item,
                    } as any;

                    state.currentInsulinUsage = currentInsulinUsage;
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsageTrend'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardInsulinUsageTrend.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsageTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsageTrend'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardSummary.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummary,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummary'),
                });
            })
            .addCase(fetchDashboardSummary.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummary,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    state.currentSummary = {
                        ...action.payload.data.item,
                        celebrationDays: action.payload.data.item.celebrationDays?.map((dayEntry) => {
                            const hasTimezoneChange = dayEntry.hasTimezoneChange;
                            return {
                                ...dayEntry,
                                percentage: dayEntry.percentage ?? 0,
                                hasTimezoneChange,
                            };
                        }),
                    };
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummary'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardSummary.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummary,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummary'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardSummaryGlucoseTrend.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummaryGlucoseTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummaryGlucoseTrend'),
                });
            })
            .addCase(fetchDashboardSummaryGlucoseTrend.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummaryGlucoseTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    state.currentSummaryGlucoseTrend = action.payload.data.item;
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummaryGlucoseTrend'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardSummaryGlucoseTrend.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummaryGlucoseTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummaryGlucoseTrend'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboard.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboard, [
                    action.meta?.arg?.beg,
                    action.meta?.arg?.end,
                ]);

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboard'),
                });
            })
            .addCase(fetchDashboard.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboard, [
                    action.meta?.arg?.beg,
                    action.meta?.arg?.end,
                ]);

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const insightIds: string[] = [];

                    if (action.payload.data.totalCount > 0) {
                        /**
                         * Scan low then high pattern groups
                         */
                        InsightHelper.PopulateInsightData(state, action.payload.data.items, insightIds);
                    }

                    state.currentDashboard = {
                        insightIds,
                    };

                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboard'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboard.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboard, [
                    action.meta?.arg?.beg,
                    action.meta?.arg?.end,
                ]);

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboard'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventReadings.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventReadings,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                state.control.activeHttpCalls[callKey] = true;

                const currEntry = state.currentReadingCombo;

                state.currentReadingCombo = {
                    ...currEntry,
                    fetched: true,
                };
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventReadings'),
                });
            })
            .addCase(fetchInsightEventReadings.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventReadings,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.currentReadingCombo = EventHelper.OrderEventReadings(
                    action.payload.id,
                    state.cachedInsights[action.payload.id2],
                    state.control.readingsInfo.fillGaps,
                    {
                        ...state.currentReadingCombo,
                        ...action.payload?.data,
                    }
                );
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventReadings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventReadings.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventReadings,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventReadings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventBoluses.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventBoluses,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                state.control.activeHttpCalls[callKey] = true;

                const currEntry = state.currentReadingCombo;

                state.currentReadingCombo = {
                    ...currEntry,
                    fetched: true,
                };
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventBoluses'),
                });
            })
            .addCase(fetchInsightEventBoluses.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventBoluses,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.currentReadingCombo = EventHelper.OrderEventReadings(
                    action.payload.id,
                    state.cachedInsights[action.payload.id2],
                    state.control.readingsInfo.fillGaps,
                    {
                        ...state.currentReadingCombo,
                        ...action.payload?.data,
                    }
                );
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventBoluses'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventBoluses.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventBoluses,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventBoluses'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventModes.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventModes,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                state.control.activeHttpCalls[callKey] = true;

                const currEntry = state.currentReadingCombo;

                state.currentReadingCombo = {
                    ...currEntry,
                    fetched: true,
                };
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventModes'),
                });
            })
            .addCase(fetchInsightEventModes.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventModes,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.currentReadingCombo = EventHelper.OrderEventReadings(
                    action.payload.id,
                    state.cachedInsights[action.payload.id2],
                    state.control.readingsInfo.fillGaps,
                    {
                        ...state.currentReadingCombo,
                        ...action.payload?.data,
                    }
                );
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventModes'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventModes.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventModes,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventModes'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchHcpReport.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchHcpReport, [
                    `${action.meta?.arg?.numDays}`,
                ]);

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.reportPending'),
                });
            })
            .addCase(fetchHcpReport.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchHcpReport, [
                    `${action.meta?.arg?.numDays}`,
                ]);

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                // download to new tab handler
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.hcpReportBody', {
                        dayCount: StringHelper.Pluralize(action.payload, 'day'),
                    }),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.hcpReportTitle'),
                    showAlways: true,
                    sticky: true,
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchHcpReport.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchHcpReport, [
                    `${action.meta?.arg?.numDays}`,
                ]);

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.reportPending'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchClinics.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchClinics);

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
            })
            .addCase(fetchClinics.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchClinics);
                const payload: Clinic[] = action.payload;

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.clinics = payload;

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchClinics.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchClinics);

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(acceptClinicInvitation.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.acceptClinicInvitation
                );

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
            })
            .addCase(acceptClinicInvitation.fulfilled, (state) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.acceptClinicInvitation
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(acceptClinicInvitation.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.acceptClinicInvitation
                );

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(denyClinicInvitation.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.denyClinicInvitation
                );

                state.control.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
            })
            .addCase(denyClinicInvitation.fulfilled, (state) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.denyClinicInvitation
                );

                if (callKey) {
                    delete state.control.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(denyClinicInvitation.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.denyClinicInvitation
                );

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            });
    },
});

export const {
    // action creators exported from homeSlice.actions available out of the box
    actions: {
        anI18NextLib: anI18NextLibActionCreator,
        allReportsRowCnt: allReportsRowCntActionCreator,
        anchorLoyaltyCongrats: anchorLoyaltyCongratsActionCreator,
        anchorLoyaltyInsulin: anchorLoyaltyInsulinActionCreator,
        anchorInsight: anchorInsightActionCreator,
        anchorEvent: anchorEventActionCreator,
        anchorAllReports: anchorAllReportsActionCreator,
        authentication: authenticationActionCreator,
        control: controlActionCreator,
        carouselSetIndex: carouselSetIndexActionCreator,
        contributingEventMetaData: contributingEventMetaDataActionCreator,
        dashboardMetaData: dashboardMetaDataActionCreator,
        endpointUrlPrefix: endpointUrlPrefixActionCreator,
        error: errorActionCreator,
        isMenuOpen: isMenuOpenActionCreator,
        isMenuVisible: isMenuVisibleActionCreator,
        insightId: insightIdActionCreator,
        intersessionDataRestore: intersessionDataRestoreActionCreator,
        intersessionDataSave: intersessionDataSaveActionCreator,
        loading: loadingActionCreator,
        updatePatient: updatePatientActionCreator,
        pollProfile: pollProfileActionCreator,
        signInUrl: signInUrlCreator,
    },
} = homeSlice;
