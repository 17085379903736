import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import SvgIcon from '@mui/material/SvgIcon';

import SlideDownTransition from './SlideDownTransition';

import { ReactComponent as SuccessIcon } from '../../assets/images/successIcon.svg';

export enum NotificationSeverity {
    Success = 'success',
}

interface IAlertToastProps {
    message: string;
    onClose: () => void;
    open: boolean;
    severity: NotificationSeverity;
    title?: string;
}

function AlertToast({ message, onClose, open, severity, title, ...otherProps }: IAlertToastProps) {
    const [exited, setExited] = useState(false);

    useEffect(() => {
        if (open) {
            setExited(false);
        }
    }, [open]);

    const handleSnackbarOnClose = (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        onClose();
    };

    const handleAlertOnClose = onClose;

    const handleSnackbarOnExited = () => {
        setExited(true);
    };

    if (!open && exited) {
        return null;
    }

    return (
        <Snackbar
            {...otherProps}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            className="AlertToast"
            onClose={handleSnackbarOnClose}
            open={open}
            TransitionComponent={SlideDownTransition}
            TransitionProps={{
                onExited: handleSnackbarOnExited,
            }}
        >
            <Alert
                iconMapping={{
                    success: <SvgIcon component={SuccessIcon} />,
                }}
                onClose={handleAlertOnClose}
                severity={severity}
            >
                {title && <AlertTitle>{title}</AlertTitle>}

                {message && (
                    <div>
                        <Trans components={{ bold: <b />, italic: <i /> }}>{message}</Trans>
                    </div>
                )}
            </Alert>
        </Snackbar>
    );
}

export default AlertToast;
