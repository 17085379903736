import { v4 as uuidv4 } from 'uuid';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { INotification, IState } from '../types';
import { homeInitialState, acceptClinicInvitation, fetchClinics, denyClinicInvitation } from '../store/home';
import { NotificationSeverity } from '../components/notification/AlertToast';
import styleGeneral from '../styles/general.module.scss';
import styleSettings from '../styles/settings.module.scss';
import ClinicDetails from '../components/settings/ClinicDetails';
import UiHelper from '../helpers/UiHelper';
import SystemHelper from '../helpers/SystemHelper';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import ConstantsHelper from '../helpers/ConstantsHelper';
import UtilityHelper from '../helpers/UtilityHelper';
import Notification from '../components/notification/Notification';

function Settings() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState<INotification | undefined>();

    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );

    useEffect(() => {
        const acceptClinicInvitationCallKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.acceptClinicInvitation
        );
        const denyClinicInvitationCallKey = UtilityHelper.MakeHttpCallKey(
            ConstantsHelper.httpCallAndErrorKeys.denyClinicInvitation
        );

        if (
            isLoading &&
            (home.errors[acceptClinicInvitationCallKey]?.level === ConstantsHelper.ErrorLevels.success ||
                home.errors[denyClinicInvitationCallKey]?.level === ConstantsHelper.ErrorLevels.success)
        ) {
            dispatch(fetchClinics(null));
            setIsLoading(false);
        }
    }, [dispatch, home.errors, isLoading]);

    const handleDontShareDataClick = async (invitationId: string) => {
        setIsLoading(true);

        try {
            const result = await dispatch(denyClinicInvitation({ invitationId }));
            unwrapResult(result as any);
            /**
             * TODO: Another PR for this story, DIB-11736, will handle the success toast message.
             */
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleShareDataClick = async (invitationId: string) => {
        setIsLoading(true);

        try {
            const result = await dispatch(acceptClinicInvitation({ invitationId }));
            unwrapResult(result as any);

            setNotification({
                message: translate('settings.dataSharedSuccessMessage'),
                severity: NotificationSeverity.Success,
            });
        } catch (error) {
            setIsLoading(false);
        }
    };

    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment translate={translate}>
            <div className={styleGeneral.body}>
                <div className={styleSettings.settings}>
                    <div className={styleSettings.heading}>{translate('settings.title')}</div>
                    <div className={styleSettings.body}>
                        <div className={styleSettings.section}>
                            <div className={styleSettings.title}>{translate('settings.myClinics')}</div>
                            <p className={styleSettings.description}>{translate('settings.description')}</p>
                            <p className={styleSettings.manageClinics}>
                                <Trans
                                    i18nKey="settings.manageClinics"
                                    components={{
                                        Link: (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
                                            <a
                                                href={SystemHelper.GetRuntimeConfig(
                                                    'REACT_APP_PODDER_CENTRAL_CLINICS_LINK'
                                                )}
                                                className={clsx(
                                                    styleGeneral.link,
                                                    styleGeneral.link_underline,
                                                    styleGeneral.link_fontWeightSemiBold
                                                )}
                                                target="_blank"
                                                rel="noreferrer"
                                            />
                                        ),
                                    }}
                                />
                            </p>
                        </div>

                        {home.clinics.length === 0 && (
                            <div className={styleSettings.section}>
                                <p className={styleSettings.noClinics}>{translate('settings.noClinics')}</p>
                            </div>
                        )}

                        {home.clinics.map((clinic, index) => {
                            const isPrescribingClinic = index === 0;
                            return (
                                <div
                                    key={uuidv4()}
                                    className={styleSettings.section}
                                    data-testid={`clinic-${clinic.clinicId}`}
                                >
                                    <ClinicDetails
                                        clinicDetails={clinic}
                                        isPrescribingClinic={isPrescribingClinic}
                                        onDontShareDataClick={handleDontShareDataClick}
                                        onShareDataClick={handleShareDataClick}
                                        shouldDisableInvitationButtons={isLoading}
                                        translate={translate}
                                    />
                                </div>
                            );
                        })}

                        <Notification data={notification} />
                    </div>
                </div>
            </div>
        </ContentWrapperAll>
    );
}

export default Settings;
